import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Fade from 'react-reveal/Fade'
import classnames from 'classnames'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import Section from '../components/common/SectionComponent/Section'
import ServicesSolution from '../components/Pages/Services/ServicesSolution/ServicesSolution'
import * as style from '../../assets/scss/pages/services.module.scss'
import keyGenerator from '../utils/keyGenerator'
import HoverHorizontalTable from '../components/reusable/HoverHorizontalTable/HoverHorizontalTable'
import * as hoverPlate from '../components/reusable/HoverPlates/hover-plates.module.scss'
import HoverPlate from '../components/reusable/HoverPlates/HoverPlate'
import HoverTextComponentSection from '../components/reusable/HoverTextComponentSection/HoverTextComponentSection'

const Services = ({ data, location }) => {
  const [activeItem, setActiveItem] = useState(0)
  const seoData = data.wpPage.metaTags
  const activateItem = (index) => {
    setActiveItem(index)
  }
  const sortData = data.wpPage.page_services.serviceDirectionItems.sort(
    (a, b) => a.menuOrder - b.menuOrder
  )

  const tableColors = {
    start: 0.02,
    end: 0.1,
  }
  return (
    <MainLayout seoData={seoData} location={location}>
      <PageMainSection
        title={data.wpPage.page_services.title}
        description={data.wpPage.page_services.description}
        image="services-dt.png"
        imageMobile="services-dt_mobile.png"
      />
      <Section hideText isLight>
        <h2 className={style.hiddenTitle}>
          {data.wpPage.page_services.directionHiddenTitle}
        </h2>
        <Fade>
          <div className={hoverPlate.wrap}>
            {sortData.map((elem, index) => {
              const plate = elem.serviceDirection
              return (
                <div className={hoverPlate.elem} key={keyGenerator(index, 1)}>
                  <HoverPlate
                    isLight
                    url={plate.button.url.uri}
                    image={plate.image}
                    button={plate.button}
                    title={plate.serviceDirectionHeader.title}
                    description={plate.serviceDirectionHeader.description}
                  />
                </div>
              )
            })}
          </div>
        </Fade>
      </Section>
      <Section
        newClass={style.accordionWrap}
        title={data.wpPage.page_services.businessesHeader.title}
      >
        <p className="text-color__gray--on-dark">
          {data.wpPage.page_services.businessesHeader.description}
        </p>
        <div className={style.wrap}>
          {data.wpPage.page_services.businessesItems.map((elem, index) => (
            <div
              className={classnames(
                style.cardWrap,
                index === activeItem && style.cardWrapActive
              )}
              role="button"
              tabIndex={0}
              onMouseEnter={() => activateItem(index)}
              onClick={() => activateItem(index)}
              onKeyDown={() => activateItem(index)}
              key={keyGenerator(index, elem.business.title)}
            >
              <ServicesSolution
                data={elem.business}
                isActive={index === activeItem}
              />
            </div>
          ))}
        </div>
      </Section>
      <HoverHorizontalTable
        isServices
        isLight
        newClass={style.serviceTables}
        tabsColors={tableColors}
        platesData={data.wpPage.page_services.processStageItems}
      >
        <div className={style.serviceTables}>
          <h2>{data.wpPage.page_services.processHeader.title}</h2>
          <p>{data.wpPage.page_services.processHeader.description}</p>
        </div>
      </HoverHorizontalTable>
      <HoverTextComponentSection
        items={data.wpPage.page_services.whyUsIt}
        title={data.wpPage.page_services.whyUsHeader.title}
        description={data.wpPage.page_services.whyUsHeader.description}
        image="/images/service-bg.png"
      />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 40 }) {
      metaTags {
        seoTitle
        seoHiddenTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_services {
        directionHiddenTitle
        description
        title
        whyUsHeader {
          description
          title
        }
        whyUsIt {
          title
          description
        }
        processStageItems {
          title
          descriptionItems {
            text
          }
        }
        processHeader {
          description
          title
        }
        serviceDirectionItems {
          ... on WpService_direction {
            menuOrder
            serviceDirection {
              serviceDirectionHeader {
                title
                description
              }
              button {
                addQuery
                text
                query
                useCustomUrl
                urlCustom
                url {
                  ... on WpPost {
                    uri
                  }
                  ... on WpPage {
                    uri
                  }
                }
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        businessesHeader {
          description
          title
        }
        businessesItems {
          business {
            ... on WpBusiness {
              title
              business {
                stages {
                  title
                }
                button {
                  addQuery
                  text
                  query
                  useCustomUrl
                  urlCustom
                  url {
                    ... on WpPost {
                      uri
                    }
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
Services.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      page_services: PropTypes.shape({
        title: PropTypes.string,
        hiddenTitle: PropTypes.string,
        description: PropTypes.string,
        button: PropTypes.shape({
          text: PropTypes.string,
          query: PropTypes.string,
        }),
        processHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        serviceDirectionItems: PropTypes.arrayOf(PropTypes.object),
        processStageItems: PropTypes.arrayOf(PropTypes.object),
        businessesItems: PropTypes.arrayOf(PropTypes.object),
        businessesHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        whyUsHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
      }),
      metaTags: PropTypes.shape({}),
    }),
    allWpIndustry: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Services
