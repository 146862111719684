import PropTypes from 'prop-types'
import * as React from 'react'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import keyGenerator from '../../../../utils/keyGenerator'
import * as style from './services-solutions.module.scss'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'

const ServicesSolution = ({ data, isActive }) => (
  <div className={className(style.card, isActive && style.activeItem)}>
    <Fade bottom>
      <div className={style.heading}>
        <span className="text-color--primary">For</span>
        <h3 className="text-color--primary">{data.title}</h3>
      </div>
    </Fade>
    <ul className={style.list}>
      {data.business.stages.map((elem, index) => (
        <Fade key={keyGenerator(index, elem.title)} bottom>
          <li className={className(style.item)}>{elem.title}</li>
        </Fade>
      ))}
    </ul>
    <Fade bottom>
      <ButtonPrimary
        btnClass={style.button}
        buttonData={data.business.button}
      />
    </Fade>
  </div>
)
ServicesSolution.propTypes = {
  isActive: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    business: PropTypes.shape({
      stages: PropTypes.arrayOf(PropTypes.object),
      button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.shape({
          uri: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
}
export default ServicesSolution
