import PropTypes from 'prop-types'
import * as React from 'react'
import className from 'classnames'
import { useEffect, useRef, useState } from 'react'
import Fade from 'react-reveal/Fade'
import keyGenerator from '../../../utils/keyGenerator'
import * as style from './hover-horizontal-hable.module.scss'
import * as section from '../../common/SectionComponent/section-component.module.scss'

const HoverHorizontalTable = ({
  platesData,
  isLight,
  newClass,
  tabsColors,
  children,
  isServices,
}) => {
  const [lineTransition, setLineTransition] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const table = useRef(null)
  const colorArr = [tabsColors.start]
  const colorRange = () => {
    function roundTo(num) {
      return +`${Math.round(`${num}e+3`)}e-3`
    }

    const step = (tabsColors.end - tabsColors.start) / (platesData.length - 1)
    for (let i = 0; i < platesData.length - 1; i++) {
      colorArr.push(roundTo(colorArr[i] + step))
    }
  }
  colorRange()

  const HorizontalCell = ({ data, isActive, isHovered, index }) => {
    const circle = useRef(null)
    const toggleActiveTab = (index) => {
      setActiveTab(index)
    }
    useEffect(() => {
      if (activeTab === platesData.length - 1) {
        setLineTransition(window.innerWidth)
      } else if (index === activeTab) {
        const width = Math.round(circle.current.getBoundingClientRect().left)
        setLineTransition(width)
      }
    }, [activeTab])

    return (
      <section
        onClick={() => toggleActiveTab(index)}
        onMouseEnter={() => toggleActiveTab(index)}
        style={
          isLight
            ? { backgroundColor: `rgba(0, 0, 0, ${colorArr[index]})` }
            : { backgroundColor: `rgba(255, 255, 255, ${colorArr[index]})` }
        }
        className={className(
          style.cell,
          isActive && style.activeItem,
          isHovered && style.hovered
        )}
      >
        <header className={style.header}>
          <h3 ref={circle} className={style.title}>
            {isServices ? data.title : data.year}
          </h3>
        </header>
        <div className={style.contentWrap}>
          <div className={style.content}>
            <Fade right>
              {isServices ? (
                <ul>
                  {data.descriptionItems.map((item, j) => (
                    <li className={style.listItem} key={keyGenerator(j, item)}>
                      {item.text}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={style.description}>{data.description}</p>
              )}
            </Fade>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section
      className={className(section.section, isLight && section.light, newClass)}
    >
      <div className="container">{children}</div>
      <div className={section.content}>
        <div ref={table} className={style.body}>
          <div className={style.lineStatic} />
          <div
            className={style.lineDynamic}
            style={{ maxWidth: `${lineTransition}px` }}
          />
          <div className={className(style.container, 'container')}>
            <div className={style.plates}>
              {platesData.map((elem, index) => (
                <HorizontalCell
                  title={elem.title}
                  index={index}
                  isActive={activeTab === index}
                  isHovered={index < activeTab}
                  data={elem}
                  key={keyGenerator(index, elem.title)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

HoverHorizontalTable.defaultProps = {
  isLight: false,
  title: '',
  description: '',
}
HoverHorizontalTable.propTypes = {
  platesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLight: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default HoverHorizontalTable
